@import 'global-variables';

.scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $background-2;
}

.scrollable::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: $background-2;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #aaa;
}