@import 'global-variables';

html, body, #root {
    height: 100%;
}

body {
    padding: 0;
    font-size: .90em;
    font-family: sans-serif;
}

.body-container {
    height: 75%;
}

.clickable {
    cursor: pointer;
}

.opaque-loading {
    opacity: 0.5;
}