/** SCSS Global Variables for InboundRodeo **/

/*
* Global Colors
* Taken from: http://fulfillment-hig.corp.amazon.com/guidelines/colors
*/
$text-color: #000000;
$text-inverted-color: #FFFFFF;

$background: #FFFFFF;
$background-2: #F0F0F0;
$highlight: #E3EEFB;
$page-header: #002244;

$primary-color: #0066CC;
$primary-shadow-color: #003366;
$secondary-color: #777777;
$secondary-color-2: #666666;
$secondary-shadow-color: #333333;
$disabled-color: #CCCCCC;
$disabled-text-color: #666666;
$default-text-color: #CCCCCC;
$border-color: #DBDBDB;

$success: #008000;
$success-shadow: #ddffd8;
$success-second: #B5E61D;
$info: #0066CC;
$info-shadow: #E3EEFB;
$warning: #FF6600;
$warning-shadow: #FCF0D9;
$error: #CC0000;
$error-shadow: #FFE5E5;

$footer-height: 70px;
