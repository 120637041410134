@import 'global-variables';

.warehouse-autosuggest {
  font-weight: bold;
  background-color: $background-2;
  caret-color: transparent;
}

.warehouse-autosuggest:focus {
  font-weight: normal;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid $border-color;
  background-color: $background;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 30vh;
  overflow: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
}

.react-autosuggest__suggestion {
  color: $text-color;
  padding: 5px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $disabled-color;
}