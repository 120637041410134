@import 'global-variables';

.alert-danger {
  color: $error;
  background-color: $error-shadow;
}

.alert-info {
  color: $info;
  background-color: $info-shadow;
}

.alert-warning {
  color: $warning;
  background-color: $warning-shadow;
}

.alert-success {
  color: $success;
  background-color: $success-shadow;
}