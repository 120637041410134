@import 'global-variables';

.header {
  background-color: $page-header;
  color: $text-inverted-color;
}

.nav-link:hover {
  background: $background;
}

.receive-nav-tabs {
  background-color: $background-2;
}