@import 'global-variables';

.item-list-selector-row {
  padding: 10px 0 0;
}

.header-row,
.even-row,
.odd-row {
  border-bottom: 1px solid $default-text-color;
}

.odd-row {
  background-color: $background-2;
}

.item-list-pagination {
  margin-bottom: 0;
}

.header-column {
  text-transform: none;
  align-items: center;
  display: flex;
  justify-content: center;
  outline: none;
  border-left: 1px solid $border-color;
}

.header-column span {
  white-space: normal;
}

.header-row {
  background-color: $background-2;
  border-top: 1px solid $secondary-color;
  border-bottom: 1px solid $secondary-color;
}

.column-wrap {
  white-space: normal;
  word-wrap: break-word;
}

.table-grid {
  outline: none;
  font-size: .8em;
  border-bottom: 1px solid $secondary-color;
}

.no-item-list-rows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: $background-2;
}

.page-item.active .page-link {
  background-color: $primary-color;
  border-color: $text-color;
}

//React table stylizes with offset margins, removing them
.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 0;
  margin-right: 0;
}