@import 'global-variables';

.aggregate-view-filter-row {
  padding: 10px 0;
}

.aggregate-table-flex {
  flex: 1 1 auto;
  overflow-x: auto;
}

.options-panel-accordion {
  max-height: 35vh;
  overflow-y: auto;
}

.options-panel-always-on-top {
  z-index: 1050;
}

.tab-pane {
  padding-bottom: $footer-height; //offset for sticky footer
}

.subworkpool-div {
  margin-left: 100px;
}

.filter-subworkpool-div {
  margin-left: 25px;
}

.btn-xs {
  font-size: 16px;
  font-weight: bold;
  line-height: 14px;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  padding: 0px;
  margin-left: 10px;
}
